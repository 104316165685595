import { defineStore } from "pinia";
import { BrandsResponse } from "~/types/ProductManagement/Brand";
import {
  FilterPrice,
  FiltersMap,
  FilterResponse,
  FilterProps,
} from "~/types/ProductManagement/filter";
import { useCategoriesStore } from "./categoriesStore";
import paths from "~/constants/paths";
const MAX_NUM_OPTION = 3;

export const useFiltersStore = defineStore("filters-store", () => {
  const categoriesFilters = ref<{
    categories?: FilterProps[];
    sub_categories?: FilterProps[];
    collections?: FilterProps[];
  }>();
  const brandsFilter = ref<FilterProps[]>();
  const optionsFilter = ref<FiltersMap>();
  const priceFilter = ref<FilterPrice>();

  const { fetchCategories, fetchSubCategories, fetchCollections } =
    useCategoriesStore();
  const nuxtApp = useNuxtApp();
  const route = useRoute();
  const router = useRouter();

  const appliedFilters = computed(() => {
    const allFilters = Object.values({
      brandsFilter: brandsFilter.value,
      ...categoriesFilters.value,
      ...optionsFilter.value,
    });

    return allFilters.reduce<FilterProps[]>(
      (acc, filterValues) => [
        ...acc,
        ...(filterValues?.filter((item) => isApplied(item.key, item.value)) ??
          []),
      ],
      [],
    );
  });

  // This limits the selected options to three at a time
  const displayedOptionsFilter = computed(() => {
    const newOptionFilters = appliedFilters.value.reduce((acc, filter) => {
      if (filter.type === "OPTION" && optionsFilter.value?.[filter.key]) {
        acc[filter.key] = optionsFilter.value[filter.key];
      }
      return acc;
    }, {} as FiltersMap);

    if (Object.values(newOptionFilters).length === MAX_NUM_OPTION) {
      return newOptionFilters;
    } else {
      return optionsFilter.value;
    }
  });

  const isApplied = (key: string, value: string) => {
    if (
      route.query[key]?.toString()?.split(",").filter(Boolean).includes(value)
    ) {
      return true;
    }

    const options = JSON.parse(route.query.options?.toString() ?? "{}");
    return options[key]?.split(",").filter(Boolean).includes(value) ?? false;
  };

  const fetchAllFilters = async (ids: string) => {
    await fetchFilters(ids);

    brandsFilter.value = await fetchBrands();

    const categories = await getCategories();
    const subCategories = await getSubcategories();
    const collections = await getCollections();

    categoriesFilters.value = {
      categories: categories,
      sub_categories: subCategories,
      collections: collections,
    };
  };

  const applyFilter = (key: string, value: string, isOption?: boolean) => {
    if (isOption) {
      // Parse current options or initialize empty object
      const currentOptions = JSON.parse(
        route.query.options?.toString() ?? "{}",
      ) as Record<string, string>;

      // Get current values for this option key
      const currentValues =
        currentOptions[key]?.split(",").filter(Boolean) ?? [];

      // Update values using the same logic as regular filters
      const newValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value];

      // Update options object
      const newOptions = {
        ...currentOptions,
        [key]: newValues.length ? newValues.join(",") : undefined,
      };

      // Clean up undefined values
      Object.keys(newOptions).forEach((k) => {
        if (newOptions[k] === undefined) {
          delete newOptions[k];
        }
      });

      router.replace({
        path: paths.products.index,
        query: {
          ...route.query,
          options: Object.keys(newOptions).length
            ? JSON.stringify(newOptions)
            : undefined,
        },
      });
    } else {
      const currentValues =
        route.query[key]?.toString()?.split(",").filter(Boolean) ?? [];

      const newValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value];

      router.replace({
        path: paths.products.index,
        query: {
          ...route.query,
          [key]: newValues.length ? newValues.join(",") : undefined,
        },
      });
    }
  };

  const clearAllFilters = () => {
    router.replace({
      path: paths.products.index,
      query: undefined,
    });
  };

  const fetchFilters = async (categoriesIds?: string) => {
    priceFilter.value = undefined;
    optionsFilter.value = undefined;

    const { res } = await useApi<FilterResponse>(
      `/api/${categoriesIds ? "v3" : "v2"}/filters?category_id=${categoriesIds}`,
    );

    priceFilter.value = res?.data.price;

    optionsFilter.value = res?.data.options.reduce((acc, option) => {
      return {
        ...acc,
        [option.title]: option.option_values.map((optionValue) => ({
          key: option.title,
          sectionTitle: option.title,
          type: "OPTION",
          label: optionValue.title,
          value: optionValue.id.toString(),
          color: optionValue.value,
        })),
      };
    }, {});
  };

  const getCategories = async () => {
    const res = await fetchCategories();

    if (res && res.length) {
      const categoriesFilter = res.map((category) => ({
        key: "categories",
        sectionTitle: "categories",
        label: category.title,
        value: category.id,
      }));

      return categoriesFilter;
    }
  };

  const getSubcategories = async () => {
    const res = await fetchSubCategories();

    if (res && res.length) {
      const subcategories = res.map((subcategory) => ({
        key: "sub_categories",
        sectionTitle: "sub categories",
        label: subcategory.title,
        value: subcategory.id,
      }));

      return subcategories;
    }
  };

  const getCollections = async () => {
    const res = await fetchCollections();

    if (res && res.length) {
      const collections = res.map((collection) => ({
        key: "collections",
        sectionTitle: "Collections",
        label: collection.title,
        value: collection.id,
      }));

      return collections;
    }
  };

  const fetchBrands = async () => {
    if (nuxtApp.$features?.brands) {
      const { res } = await useApi<BrandsResponse>(`/api/v1/brands`);

      const brandsFilter = res?.data.map((brand) => ({
        key: "brands",
        sectionTitle: "Brands",
        label: brand.title,
        value: brand.id,
      }));

      if (
        brandsFilter?.length === 0 &&
        !nuxtApp.$features?.categories?.configs?.showEmptyCategories
      ) {
        return undefined;
      } else {
        return brandsFilter;
      }
    }
  };

  return {
    categoriesFilters,
    brandsFilter,
    optionsFilter,
    priceFilter,
    appliedFilters,
    displayedOptionsFilter,
    applyFilter,
    isApplied,
    fetchFilters,
    clearAllFilters,
    fetchAllFilters,
  };
});
